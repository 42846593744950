<template>
  <section v-if="dataLoaded">

    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'view-client', params: resourceId }">
              {{ resourceName }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              Edit {{ resourceName }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col
        md="12"
        class="mb-2 text-right"
      >
        <b-button
          variant="primary"
          :to="{ name: 'view-client', params: resourceId }"
        >
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="align-text-top"
          />  View {{ resourceName }}
        </b-button>
      </b-col>
    </b-row>

    <!-- edit resource card  -->
    <b-card class="p-1">
      <b-card-title class="pl-1 border-left-primary border-left-3">
        Edit {{ resourceName }}
      </b-card-title>
      <!-- form -->
      <validation-observer
        ref="resourceForm"
        #default="{invalid, dirty}"
      >
        <b-form @submit.prevent="updateClient">
          <b-row>
            <!-- name -->
            <b-col cols="12">
              <b-form-group
                label="Name of Client"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name of Client"
                  rules="required"
                  vid="name"
                >
                  <b-form-input
                    id="name"
                    v-model="clientData.name"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Name of Client"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- address_1 -->
            <b-col cols="12">
              <b-form-group
                label="Address (Line 1)"
                label-for="address_1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address (Line 1)"
                  vid="address_1"
                >
                  <b-form-input
                    id="address_1"
                    v-model="clientData.address_1"
                    name="address_1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Address (Line 1)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- address_2 -->
            <b-col cols="12">
              <b-form-group
                label="Address (Line 2)"
                label-for="address_2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address (Line 2)"
                  vid="address_2"
                >
                  <b-form-input
                    id="address_2"
                    v-model="clientData.address_2"
                    name="address_2"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Address (Line 2)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- city -->
            <b-col cols="6">
              <b-form-group
                label="City"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  vid="city"
                >
                  <b-form-input
                    id="city"
                    v-model="clientData.city"
                    name="city"
                    :state="errors.length > 0 ? false:null"
                    placeholder="City"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- state/province -->
            <b-col cols="6">
              <b-form-group
                label="State/Province"
                label-for="province"
              >
                <validation-provider
                  #default="{ errors }"
                  name="State/Province"
                  vid="province"
                >
                  <b-form-input
                    id="province"
                    v-model="clientData.province"
                    name="province"
                    :state="errors.length > 0 ? false:null"
                    placeholder="State/Province"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- phone_number -->
            <b-col cols="6">
              <b-form-group
                label="Phone Number"
                label-for="phone_number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone Number"
                  vid="phone_number"
                >
                  <b-form-input
                    id="phone_number"
                    v-model="clientData.phone_number"
                    name="phone_number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Phone Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit -->
            <b-col
              cols="12"
              class="mt-1 text-right"
            >
              <b-button
                type="submit"
                variant="primary"
                :disabled="invalid || !dirty"
              >
                Update
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BCard,
  BFormGroup, BFormInput, BForm, BButton, BCardTitle,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCardTitle,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      resourceName: 'Client',
      resourceNamePlural: 'Clients',
      adminAccess: false,
      adminSuffix: '', // 'AsRoot' if root user
      userData: JSON.parse(localStorage.getItem('userData')),

      inviteEmail: '',
      clientData: {
        name: '',
        address_1: '',
        address_2: '',
        city: '',
        province: '',
        phone_number: '',
      },
      required,
      email,
      dataLoaded: false,
      errorModalMessage: null,
    }
  },
  computed: {
    resourceId() {
      return this.$route.params.id
    },
  },
  created() {
    if (this.userData.role.id === 1) {
      // set admin access if root user
      this.adminAccess = true
      this.adminSuffix = 'AsRoot'
      this.getClient()
    } else {
      // if not root user
      this.getClient()
    }
  },
  methods: {
    getClient() {
      useJwt[`getClient${this.adminSuffix}`](this.$route.params.id)
        .then(response => {
          console.log('getClient', response.data.data)
          const resourceData = response.data.data
          // populate resource form fields
          this.clientData = resourceData
          // indicate that data fetch was successful
          this.dataLoaded = true
        })
        .catch(error => {
          if (error.response.status === 403) { // forbidden
            // this.$router.push({ name: 'error-404' })
          } else if (error.response.status === 404) { // not found
            // this.$router.push({ name: 'error-404' })
          }
        })
    },
    updateClient() {
      this.$refs.resourceForm.validate().then(success => {
        if (success) {
          // reset form validation state
          // so we can track if form data is dirty
          this.$refs.resourceForm.reset()

          if (!this.clientData.address_1) {
            delete this.clientData.address_1
          }
          if (!this.clientData.address_2) {
            delete this.clientData.address_2
          }
          if (!this.clientData.city) {
            delete this.clientData.city
          }
          if (!this.clientData.province) {
            delete this.clientData.province
          }
          if (!this.clientData.phone_number) {
            delete this.clientData.phone_number
          }

          useJwt.updateClient(
            this.$route.params.id,
            this.clientData,
          )
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Updated',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Client updated successfully.',
                },
              })
              this.$router.push({ name: 'view-client', params: { id: this.resourceId } })
            })
            .catch(error => {
              if (error.response.status === 400) {
                // highlights all applicable erroneous inputs mentioned by the server.
                this.$refs.resourceForm.setErrors(error.response.data.error)
              }
            })
        }
      })
    },
    showError() {
      this.$bvModal.msgBoxOk(this.errorModalMessage, {
        title: 'Error',
        hideHeaderClose: true,
        okVariant: 'danger',
        centered: true,
        modalClass: 'modal-warning',
      })
    },
  },
}
</script>

<style lang="scss">

</style>
