var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataLoaded)?_c('section',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"breadcrumb-wrapper"},[_c('b-breadcrumb',[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'dashboard' }}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"HomeIcon","size":"16"}}),_vm._v(" Dashboard ")],1),_c('b-breadcrumb-item',{attrs:{"to":{ name: 'view-client', params: _vm.resourceId }}},[_vm._v(" "+_vm._s(_vm.resourceName)+" ")]),_c('b-breadcrumb-item',{attrs:{"active":true}},[_vm._v(" Edit "+_vm._s(_vm.resourceName)+" ")])],1)],1)]),_c('b-col',{staticClass:"mb-2 text-right",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","to":{ name: 'view-client', params: _vm.resourceId }}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"EyeIcon","size":"16"}}),_vm._v(" View "+_vm._s(_vm.resourceName)+" ")],1)],1)],1),_c('b-card',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"pl-1 border-left-primary border-left-3"},[_vm._v(" Edit "+_vm._s(_vm.resourceName)+" ")]),_c('validation-observer',{ref:"resourceForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateClient.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name of Client","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name of Client","rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","state":errors.length > 0 ? false:null,"placeholder":"Name of Client"},model:{value:(_vm.clientData.name),callback:function ($$v) {_vm.$set(_vm.clientData, "name", $$v)},expression:"clientData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Address (Line 1)","label-for":"address_1"}},[_c('validation-provider',{attrs:{"name":"Address (Line 1)","vid":"address_1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address_1","name":"address_1","state":errors.length > 0 ? false:null,"placeholder":"Address (Line 1)"},model:{value:(_vm.clientData.address_1),callback:function ($$v) {_vm.$set(_vm.clientData, "address_1", $$v)},expression:"clientData.address_1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Address (Line 2)","label-for":"address_2"}},[_c('validation-provider',{attrs:{"name":"Address (Line 2)","vid":"address_2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address_2","name":"address_2","state":errors.length > 0 ? false:null,"placeholder":"Address (Line 2)"},model:{value:(_vm.clientData.address_2),callback:function ($$v) {_vm.$set(_vm.clientData, "address_2", $$v)},expression:"clientData.address_2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"City","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"City","vid":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","name":"city","state":errors.length > 0 ? false:null,"placeholder":"City"},model:{value:(_vm.clientData.city),callback:function ($$v) {_vm.$set(_vm.clientData, "city", $$v)},expression:"clientData.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"State/Province","label-for":"province"}},[_c('validation-provider',{attrs:{"name":"State/Province","vid":"province"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"province","name":"province","state":errors.length > 0 ? false:null,"placeholder":"State/Province"},model:{value:(_vm.clientData.province),callback:function ($$v) {_vm.$set(_vm.clientData, "province", $$v)},expression:"clientData.province"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"phone_number"}},[_c('validation-provider',{attrs:{"name":"Phone Number","vid":"phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone_number","name":"phone_number","state":errors.length > 0 ? false:null,"placeholder":"Phone Number"},model:{value:(_vm.clientData.phone_number),callback:function ($$v) {_vm.$set(_vm.clientData, "phone_number", $$v)},expression:"clientData.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1 text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || !dirty}},[_vm._v(" Update ")])],1)],1)],1)]}}],null,false,520376822)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }